import type { ComponentProps } from "react";
import { LocationForm } from "./LocationForm";
import { jobApi, toasted, useLookupCustomers } from "./helpers";

type LocationFormProps = ComponentProps<typeof LocationForm>;

export const LocationFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<LocationFormProps, "onSubmit" | "defaultValues">) => {
	const lookupCustomers = useLookupCustomers();
	return (
		<LocationForm
			defaultValues={defaultValues}
			lookupCustomers={lookupCustomers}
			onSubmit={async (data) => {
				const { id, ...rest } = data;
				const processData = async () => {
					if (id) {
						await jobApi.location.locationUpdate({ id, ...rest });
						onSubmit(data);
					} else {
						const result = await jobApi.location.locationCreate({ ...rest });
						type TheRightType = Parameters<typeof onSubmit>[0];
						onSubmit({ ...result.data, ...data } as TheRightType);
					}
				};
				toasted(processData(), id ? "Updating Location" : "Creating Location");
			}}
		/>
	);
};
