import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Flex } from "./GenericFlex";
import {
	GenericFormButtons,
	InputMap,
	InputSelect,
	InputText,
	InputTextArea,
	type LoadOptionsFn,
} from "./GenericForm";
import { type LocationFormObject, LocationSchema } from "./Location";

type LocationFormProps = {
	defaultValues?: Partial<LocationFormObject>;
	onSubmit: (data: LocationFormObject) => void;
	lookupCustomers: LoadOptionsFn;
};
export const LocationForm = ({
	defaultValues,
	onSubmit,
	lookupCustomers,
}: LocationFormProps) => {
	const { handleSubmit, reset, control } = useForm<LocationFormObject>({
		resolver: yupResolver(LocationSchema),
		defaultValues,
	});
	return (
		<form
			className="k-form"
			onSubmit={handleSubmit((data) => onSubmit?.(data))}
		>
			<Flex>
				<div>
					<InputText control={control} schema={LocationSchema} name="name" />
					<InputTextArea
						control={control}
						schema={LocationSchema}
						name="address"
					/>
				</div>
				<div>
					<InputSelect
						control={control}
						schema={LocationSchema}
						name="customerId"
						loadOptions={lookupCustomers}
					/>
					<InputTextArea
						control={control}
						schema={LocationSchema}
						name="notes"
					/>
				</div>
			</Flex>
			<InputMap
				control={control}
				longitude="longitude"
				latitude="latitude"
				address="address"
			/>
			<GenericFormButtons onReset={() => reset(defaultValues)} />
		</form>
	);
};
