import "@progress/kendo-font-icons/dist/index.css";
import { IconsContext } from "@progress/kendo-react-common";
import "@progress/kendo-theme-default/dist/all.scss";
import "nprogress/nprogress.css";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import { PersistGate } from "redux-persist/integration/react";
import {
	clientPersistor,
	clientStore,
} from "../libs/common/stores/src/lib/client";
import { App } from "./App";
import "./styles.scss";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { useEffectOnce, useToggle } from "react-use";

const localStoragePersister = createSyncStoragePersister({
	storage: window.localStorage,
});
const queryClient = new QueryClient();
persistQueryClient({ queryClient, persister: localStoragePersister });

const ReactQueryDevtoolsWrapper = () => {
	const [devtools, toggleDevtools] = useToggle(false);
	useEffectOnce(() => void Object.assign(window, { toggleDevtools }));
	if (!devtools) return null;
	return <ReactQueryDevtools buttonPosition="top-right" position="right" />;
};

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<QueryClientProvider client={queryClient}>
		<Provider store={clientStore}>
			<PersistGate persistor={clientPersistor}>
				<IconsContext.Provider value={{ type: "svg" }}>
					<StrictMode>
						<BrowserRouter>
							<App />
							<ReactQueryDevtoolsWrapper />
						</BrowserRouter>
					</StrictMode>
				</IconsContext.Provider>
			</PersistGate>
		</Provider>
	</QueryClientProvider>,
);
