import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Flex } from "./GenericFlex";
import {
	GenericFormButtons,
	InputDateTime,
	InputDropzone,
	InputSelect,
	InputTextArea,
	type LoadOptionsFn,
} from "./GenericForm";
import { type LoadFormObject, LoadSchema } from "./Load";

type LoadFormProps = {
	defaultValues?: Partial<LoadFormObject>;
	onSubmit: (data: LoadFormObject) => void;
	lookupLoadTypes: LoadOptionsFn;
	lookupUsers: LoadOptionsFn;
	lookupLocations: LoadOptionsFn;
	lookupTrailers: LoadOptionsFn;
};

export const LoadForm = ({
	defaultValues,
	onSubmit,
	lookupLoadTypes,
	lookupLocations,
	lookupUsers,
	lookupTrailers,
}: LoadFormProps) => {
	const { handleSubmit, reset, control } = useForm<LoadFormObject>({
		resolver: yupResolver(LoadSchema),
		defaultValues,
	});
	return (
		<form
			className="k-form"
			onSubmit={handleSubmit((data) => onSubmit?.(data))}
		>
			<Flex>
				<div>
					<InputSelect
						control={control}
						schema={LoadSchema}
						name="loadTypeId"
						loadOptions={lookupLoadTypes}
					/>
					<InputSelect
						control={control}
						schema={LoadSchema}
						name="startLocationId"
						loadOptions={lookupLocations}
					/>
					<InputDateTime
						control={control}
						schema={LoadSchema}
						name="startDate"
					/>
					<InputSelect
						control={control}
						schema={LoadSchema}
						name="endLocationId"
						loadOptions={lookupLocations}
					/>
					<InputDateTime control={control} schema={LoadSchema} name="endDate" />
				</div>
				<div>
					<InputSelect
						control={control}
						schema={LoadSchema}
						name="trailerId"
						loadOptions={lookupTrailers}
					/>
					<InputSelect
						control={control}
						schema={LoadSchema}
						name="assignedTo"
						loadOptions={lookupUsers}
					/>
					<InputTextArea control={control} schema={LoadSchema} name="notes" />
					<InputDropzone
						control={control}
						schema={LoadSchema}
						name="documents"
					/>
				</div>
			</Flex>
			<GenericFormButtons onReset={() => reset(defaultValues)} />
		</form>
	);
};
