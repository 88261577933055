import type { Location, NavigateFunction } from "react-router-dom";

export const navigateAfterBusinessUnitSelection = (
	navigate: NavigateFunction,
	location: Location,
) => {
	setTimeout(() => {
		const previousPath = location.state?.from;
		if (previousPath?.match(/.*\/[0-9]+$/)) {
			const previousPathComponents = previousPath.split("/");
			previousPathComponents.splice(-1);
			const pathToNavigateTo = previousPathComponents.join("/");
			navigate(pathToNavigateTo);
		} else if (previousPath) {
			navigate(-1);
		} else {
			navigate("/");
		}
	}, 1);
};
